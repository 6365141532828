import React from "react"

const Footer = () => {
  return (
    <footer id="colophon" className="site-footer py-5 bg-darkest">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <div className="site-info">
              <span>
                Copyright © {new Date().getFullYear()} By Sea. All rights
                reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
