import React from "react"

const Container = ({ children }) => {
  return <div className="container">{children}</div>
}

const Row = ({ children, className = "" }) => {
  return <div className={`row ${className}`}>{children}</div>
}

const Column = ({ children, className = "" }) => {
  return <div className={`col ${className}`}>{children}</div>
}

export { Container, Row, Column }
