import { Link } from "gatsby"
import React from "react"

const NavItem = ({ linkTo, label, location }) => {
  let classNames = "nav-item"

  if (location.pathname === linkTo) {
    classNames += " active"
  } else if (location.pathname !== "/" && linkTo === "/") {
    // we are matching the root path on a subpage -- do nothing
  } else if (location.pathname.indexOf(linkTo) === 0) {
    classNames += " active"
  }

  return (
    <li className={classNames}>
      <Link to={linkTo}>{label}</Link>
    </li>
  )
}

export { NavItem }
