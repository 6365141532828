import { Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { NavItem } from "./nav"
import Img from "gatsby-image"

const Header = ({ siteTitle, location }) => {
  const { byseaLogo } = useStaticQuery(
    graphql`
      query {
        byseaLogo: file(relativePath: { eq: "bysea-watermark.png" }) {
          childImageSharp {
            fixed(height: 60, width: 60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <header id="masthead" className="site-header">
      <nav className="navbar navbar-expand-md navbar-dark bg-transparent d-none d-md-flex">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <div className="site-branding">
            <p className="navbar-brand site-title pr-5 h1">
              <Link to="/" rel="home">
                {siteTitle}
              </Link>
            </p>
          </div>
          <ul id="primary-menu" className="navbar-nav">
            <NavItem linkTo="/" label="Home" location={location} />
            <NavItem linkTo="/about-us" label="About Us" location={location} />
            <NavItem linkTo="/releases" label="Releases" location={location} />
            <NavItem
              linkTo="/contact-us"
              label="Contact Us"
              location={location}
            />
          </ul>
        </div>
      </nav>

      {location.pathname !== "/" && (
        <div className="mobile-header d-md-none d-flex justify-content-center align-items-center pt-4">
          <Link to="/" title="Go to the By Sea homepage">
            <Img fixed={byseaLogo.childImageSharp.fixed} className="d-flex" />
          </Link>
        </div>
      )}

      <nav
        id="mobile-navigation"
        className="navbar fixed-bottom d-md-none navbar-dark bg-darkest main-navigation py-2"
        style={{ boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.5)" }}
      >
        <div
          className="collapse navbar-collapse text-center pb-5"
          id="navbarTogglerDemo02"
        >
          <div className="site-branding d-flex justify-content-center">
            <p className="navbar-brand site-title text-center h1 p-0 m-0">
              <Link to="/" rel="home">
                {siteTitle}
              </Link>
            </p>
          </div>

          <ul id="primary-menu" className="navbar-nav">
            <NavItem linkTo="/" label="Home" location={location} />
            <NavItem linkTo="/about-us" label="About Us" location={location} />
            <NavItem linkTo="/releases" label="Releases" location={location} />
            <NavItem
              linkTo="/contact-us"
              label="Contact Us"
              location={location}
            />
          </ul>
        </div>

        <button
          className="btn btn-outline-primary mx-auto mb-3 mt-1"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          MENU
        </button>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
